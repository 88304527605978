<template>
  <div class="list-card-add__actions">
    <button class="button button--primary" @click="showModal = true">Regalar</button>
    <modal-dialog
      :show="showModal"
      :product="product"
      target-class="modal__card--mini"
      @close="showModal = false"
    >
      <div class="modal-regalo-resumen">
        <h4 class="modal-regalo-resumen__titulo">{{ $t("guest.contributions.summaryTitle") }}</h4>
        <div class="modal-regalo-resumen__subtitulo">
          {{ $t("guest.contributions.summarySubtitle", { name: list.ownerName }) }}
        </div>
        <div class="modal-regalo-resumen__card">
          <div class="rrc">
            <div class="rrc__cols">
              <div class="rrc__cover">
                <img :src="$getImgUrl(product.photo)" :alt="product.name" />
              </div>
              <div class="rrc__summary">
                <div class="rrc__titulo">{{ product.name }}</div>
                <div class="rrc__marca">{{ product.brand }}</div>
                <dl class="rrc__resume">
                  <div class="rrc__resume-item">
                    <dt>Tu aportación</dt>
                    <dd>40 €</dd>
                  </div>
                  <div class="rrc__resume-item">
                    <dt>Tarifa del servicio*</dt>
                    <dd>1,42 €</dd>
                  </div>
                  <div class="rrc__resume-item rrc__resume-item--total">
                    <dt>Total</dt>
                    <dd>41,42 €</dd>
                  </div>
                </dl>
              </div>
            </div>
            <div class="rrc__notice">
              *1,5% coste el pago con tarjeta + 1€ para HelloBB.
            </div>
          </div>
        </div>
        <div class="modal-regalo-resumen__checkout">
          <div class="modal-regalo-resumen__checkout-title">Pago</div>
          <div class="modal-regalo-resumen__checkout-subtitle">
            Todas las transacciones son seguras y están encriptadas.
          </div>

          <div class="modal-regalo-resumen__stripe">
            <!-- Aqui stripe -->
          </div>
        </div>
        <div class="modal-regalo-resumen__actions">
          <bbButton label="Realizar aportación" class="button--block"></bbButton>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog";
import { VTooltip } from "floating-vue";
import { mapGetters } from "vuex";

import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "ListProductRegalarCheckout",
  components: {
    ModalDialog,
    bbButton,
  },
  data() {
    return {
      showModal: false,
      rangeValue: 30,
      swidth: null,
      rangeSize: null,
      shortcuts: [
        {
          value: 10,
        },
        {
          value: 20,
        },
        {
          value: 30,
        },
      ],
    };
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      retailers: "retailers",
    }),
    retailersId() {
      return "product_" + this.product.id + "_retailers";
    },
  },
  mounted() {
    this.rangeValue = this.product.price;
    this.shortcuts[0] = this.product.price * 0.24;
    this.shortcuts[1] = this.product.price * 0.5;
    this.shortcuts[2] = this.product.price * 0.75;
  },
  // created() {
  //   window.addeventlistener("resize", this.screenwidth);
  // },
  // mounted() {
  //   this.screenwidth();
  //   this.updateRangeSize();
  //   this.$nextTick(() => {
  //     this.updateRangeSize();
  //     window.addEventListener("resize", this.updateRangeSize);
  //   });
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.screenwidth);
  // },
  // methods: {
  //   screenwidth() {
  //     this.swidth = this.$refs.container?.offsetwidth;
  //   },
  //   updateRangeSize() {
  //     const rangeInput = document.getElementById("range");
  //     if (rangeInput) {
  //       this.rangeSize = rangeInput.offsetWidth;
  //     }
  //   },
  // },
};
</script>
