<template>
  <div class="flux-fondos-step">
    <div class="flux-fondos-step__msg">
      {{ $t("guest.funds.contributeModalSummary", { fundTitle: fund.title }) }}
    </div>

    <div class="modal-aportar-resumen__card">
      <div class="rrc">
        <div class="rrc__cols">
          <div class="rrc__cover">
            <img :src="$fundIcons[fund.fundTypeId]" :alt="fund.title" />
          </div>
          <div class="rrc__summary">
            <div class="rrc__titulo">{{ fund.title }}</div>
            <!--div class="rrc__marca">{{ product.brand }}</div-->
            <dl class="rrc__resume">
              <div class="rrc__resume-item">
                <dt>{{ $t("guest.contribution.yourContribution") }}</dt>
                <dd>{{ (Math.round(rangeValue * 100) / 100).toFixed(2) }} €</dd>
              </div>
              <div class="rrc__resume-item" v-if="serviceFee">
                <dt>{{ $t("guest.contribution.serviceFee") }}*</dt>
                <dd>{{ (Math.round(serviceFee * 100) / 100).toFixed(2) }} €</dd>
              </div>
              <div class="rrc__resume-item rrc__resume-item--total" v-if="serviceFee">
                <dt>{{ $t("guest.contribution.total") }}</dt>
                <dd>{{ totalPayment.toFixed(2) }} €</dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="rrc__notice" v-if="serviceFee">
          {{ $t("guest.contribution.feeExplanation") }}
        </div>
      </div>
    </div>

    <div class="flux-fondos-step__msg flux-fondos-step__msg--mb">
      <div class="modal-regalo-resumen__checkout-title">{{ $t("guest.contribution.payment") }}</div>
      <div class="modal-regalo-resumen__checkout-subtitle">
        {{ $t("guest.contribution.paymentSecurityNote") }}
      </div>
    </div>

    <div class="flux-fondos-step__actions">
      <loading-button v-if="loading"></loading-button>
      <button v-else class="button button--primary button--block" @click="$emit('finish')">{{ $t("guest.contribution.contribute") }}</button>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";

import LoadingButton from "../../../components/LoadingButton";
import ServiceFee from "../../../Mixins/ServiceFee";
import { FEES_SUPPORTED_BY } from "../../../constants/contributions-configuration";

export default {
  name: "FluxFondosStep2",
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    fund: {
      type: Object,
      required: true
    },
    body: {
      type: Object,
      required: true
    },
  },
  mixins: [
    ServiceFee,
  ],
  computed: {
    ...mapGetters({
      contributionsConfigurationFeesSupportedBy: "contributionsConfigurationFeesSupportedBy",
    }),
    rangeValue() {
      return this.body.amount;
    },
    serviceFee() {
      if (this.contributionsConfigurationFeesSupportedBy === FEES_SUPPORTED_BY.owner) {
        return 0;
      }

      return this.getServiceFee(this.rangeValue);
    },
    paymentAmount() {
      //return Math.round((parseFloat(this.rangeValue) + this.serviceFee) * 100) / 100
      return Math.round(parseFloat(this.rangeValue) * 100) / 100;
    },
    totalPayment() {
      return Math.round((parseFloat(this.rangeValue) + this.serviceFee) * 100) / 100;
    },
  },
  components: {
    'loading-button': LoadingButton
  },
};
</script>
