<template>
  <transition name="bbFade" @after-enter="showContent = true">
    <div class="modal" v-if="show" @click="showContent = false">
      <transition name="bbUp" @after-leave="$emit('close')">
        <div v-show="showContent" class="modal__card" @click.stop="">
          <div class="modal__dialog">
            <div class="modal__header">
<!--             Back button  AMAGAT amb DISPLAY NONE via CSS -> en control de QA s'ha descartat aquesta funcionalitat-->
              <div class="modal__back" v-show="step > 1">
                <img
                  @click="step--"
                  src="../../../assets/img/ico/ico-arrow-back.svg"
                  alt="Atras"
                />
              </div>
              <div class="modal__title">
                <slot name="titulo"></slot>
              </div>
              <div class="modal__close">
                <img
                  src="../../../assets/img/ico/ico-close.svg"
                  alt="Cerrar"
                  @click="showContent = false"
                />
              </div>
            </div>
            <div class="modal__content" ref="scroll_container">
              <div>
                <section class="flux-fondos">
                  <div class="flux-fondos__header">
                    <small class="flux-fondos__step">
                      <span>{{ $t("guest.funds.stepNumerator", { step }) }}</span>
                    </small>
                    <h3 class="flux-fondos__title">
                      {{ steps[step] }}
                    </h3>
                  </div>
                  <div :class="hasLeftContent ? 'flux-fondos__content' : ''">
                    <div v-if="hasLeftContent" class="flux-fondos__left">
                      <div class="flux-fondos__name" v-text="fund.title" />
                      <div class="ico-box ico-box--flex">
                        <img
                          :src="$fundIcons[fund.fundTypeId]"
                          alt="Tipo de fondo"
                        />
                      </div>
                    </div>
                    <div class="flux-fondos__right">
                      <FluxFondosStep1
                        v-show="step === 1"
                        :loading="loading"
                        :fund="fund"
                        :list="list"
                        :payment-method="paymentMethod"
                        @next="nextSecondStep"
                      ></FluxFondosStep1>
                      <FluxFondosStep2
                        v-show="step === 2"
                        :loading="loading"
                        :fund="fund"
                        :list="list"
                        :body="body || {}"
                        @finish="finish"
                      ></FluxFondosStep2>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import FluxFondosStep1 from "../components/FluxFondosStep1.vue";
import FluxFondosStep2 from "../components/FluxFondosStep2.vue";
import CanBeClosedByEscKey from "../../../Mixins/CanBeClosedByEscKey";

export default {
  name: "FluxFondos",
  mixins: [CanBeClosedByEscKey],
  components: {
    FluxFondosStep1,
    FluxFondosStep2,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    fund: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      step: 1,
      paymentMethod: null,
      body: null,
      showContent: false,
    };
  },
  computed: {
    steps() {
      return {
        1: this.$t("guest.funds.contributeModalTitle1"),
        2: this.$t("guest.funds.contributeModalTitle2"),
      };
    },
    hasLeftContent() {
      const lastStep = Object.keys(this.steps)
        .sort((a, b) => a - b)
        .pop();

      return this.step < lastStep;
    },
  },
  methods: {
    close() {
      this.showContent = false;
      this.$emit("close");
    },
    nextFirstStep(paymentMethod) {
      this.paymentMethod = paymentMethod;
      this.step++;
      this.resetScroll()
    },
    resetScroll() {
      this.$refs.scroll_container.scrollTop = 0
    },
    nextSecondStep(body) {
      this.body = body;
      this.step++;
      this.resetScroll()
    },
    /*
    async submitFund() {
      try {
        await this.$store.dispatch('contributeToFund',{fund: this.fund, contribution: this.body });
      } catch (error) {
        console.log(error);
      }
    },
    */
    async finish() {
      this.loading = true;

      const fundImageUrl = (
        new URL(
          this.$fundIcons[this.fund.fundTypeId],
          document.location
        )
      ).href;

      const response = await this.$store.dispatch("postCheckoutFund", {
        accountId: this.list.stripeAccountId,
        amountCents: this.body.amount * 100,
        fundName: this.fund.title,
        fundImage: fundImageUrl,
        fundId: this.fund.id,
        listId: this.list.id,
        contributorName: this.body.name,
      });
      if (response.status === 200) {
        const checkoutUrl = response.data.url;
        if (typeof checkoutUrl === "string" && checkoutUrl.trim() !== "") {
          window.location = checkoutUrl;

          return;
        }
      }

      // The code below is only executed if there's an error.

      this.loading = false;
      this.step = 1;
      this.$emit("finish");
    },
  },
};
</script>

<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s opacity ease-out;
}
</style>
