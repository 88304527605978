<template>
  <div class="">
    <transition name="slide-fade">
      <div>
        <div class="ml-filters__status-desk">
          <div class="bb-filter-item">
            <div class="bb-filter-item__label">{{ $t("generic.price") }}</div>
            <div class="bb-filter" v-click-outside="config">
              <div
                class="bb-filter__select"
                @click="toggle()"
                :class="toggleFilter ? 'bb-filter__select--active' : ''"
              >
                {{ label }}

                <img
                  src="../../../assets/img/ico/ico-chevron-up.svg"
                  loading="lazy"
                  alt=""
                  width="16px"
                  :class="
                    toggleFilter
                      ? 'bb-filter__chevron bb-filter__chevron--active'
                      : 'bb-filter__chevron '
                  "
                />
              </div>
              <div class="bb-filter__items" v-show="toggleFilter">
                <label
                  class="bb-filter__item bb-filter__item--option"
                  v-for="(priceRange, index) in priceRanges"
                  :key="index"
                  :id="'checkbox-' + priceRange.id + '-label'"
                  @click="change(priceRange.id)"
                  :class="priceRange.id == selectedId ? 'bb-filter__item--active' : ''"
                >
                  <span>{{ priceRange.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-filters__status-mobile">
          <div class="bb-filter-item">
            <div class="bb-filter-item">
              <div class="bb-filter-item__label">{{ $t("generic.price") }}</div>
              <div class="bb-filter-radio">
                <div
                  v-for="(priceRange, index) in priceRanges"
                  class="bb-filter-radio__item"
                  :key="index"
                  :id="'state_' + index"
                >
                  <input
                    v-bind:value="priceRange.id"
                    v-model="selectedId"
                    type="radio"
                    :id="'checkbox-' + priceRange.id + '-input'"
                    :name="'checkbox-' + priceRange.id + '-label'"
                    class=""
                    :checked="priceRange.id == selectedId"
                  />
                  <label :for="'checkbox-' + index + '-input'" class="w-form-label">
                    {{ priceRange.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import IsFilter from "./Mixins/IsFilter";
import CloseOnClickOutside from "../../../components/Mixins/CloseOnClickOutside";
import CanBeClosedByEscKey from "../../../Mixins/CanBeClosedByEscKey";
import { mapGetters } from "vuex";

export default {
  name: "PriceRangeFilter",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside, IsFilter],
  data() {
    return {
      selectedId: 99,
      priceRanges: [],
    };
  },
  computed: {
    ...mapGetters({
      guestProductFilters: "guestProductFilters",
    }),
    selectedPriceRange() {
      const id = this.selectedId;
      const priceRanges = this.priceRanges || [];

      const result = priceRanges.find(pr => pr.id === id);

      return result || null;
    },
    label() {
      return this.selectedPriceRange && this.selectedPriceRange.id !== 99
        ? this.priceRanges.find((pr) => pr.id === this.selectedPriceRange.id).name
        : this.$t("generic.prices");
    },
  },
  watch: {
    selectedId: {
      handler() {
        if (this.enableWatch) {
          this.applyFilter();
        }
      },
      immediate: true,
    },
  },
  methods: {
    applyFilter() {
      let newFilters = [...this.guestProductFilters];

      const found = newFilters.find((filter) => filter.name === "priceRange");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }

      if (this.selectedPriceRange && this.selectedPriceRange.id !== 99) {
        newFilters.push({
          name: "priceRange",
          values: {
            priceRange: this.selectedPriceRange,
          },
        });
      }

      this.$setGuestProductFilters(newFilters);
      this.close();
    },
    change(selectedId) {
      this.selectedId = selectedId;
    },
  },
  created() {
    this.priceRanges = [
      {
        id: 99,
        name: this.$t("filters.all"),
        min: 0,
        max: 9999999.99,
      },
      {
        id: 1,
        name: this.$t("filters.under50"),
        min: 0,
        max: 49.99,
      },
      {
        id: 2,
        name: this.$t("filters.between50And100"),
        min: 50,
        max: 100,
      },
      {
        id: 3,
        name: this.$t("filters.above100"),
        min: 100.01,
        max: null,
      },
    ];
  },
  mounted() {
    this.$nextTick(() => {
      const priceRangeFound = this.guestProductFilters.find((filter) => filter.name === "priceRange");
      if (priceRangeFound) {
        this.selectedId = priceRangeFound.values.priceRange.id;
      }

      this.enableWatch = true;
    });
  },
};
</script>
