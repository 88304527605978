<template>
  <div class="flux-fondos-step">
    <div class="flux-fondos-step__msg">{{ $t("guest.funds.contributeModalDescription", { name: list.ownerName }) }}</div>
    <div class="flux-fondos-form">
      <div class="bb-form-item">
        <label class="bb-form-label" for="name">{{ $t("generic.name") }}</label>
        <input
          id="name"
          type="text"
          class="bb-form"
          v-model="name"
          required
          :placeholder="$t('guest.funds.contributeModalNamePlaceholder')"
        />
      </div>
      <div class="bb-form-item">
        <label class="bb-form-label" for="amount">{{ $t("guest.funds.contributeModalHowMuch") }}</label>
        <input id="amount" type="number" class="bb-form" placeholder="0" v-model="amount" required/>
      </div>
    </div>
    <div class="flux-fondos-step__actions">
      <button class="button button--primary button--block" @click="next" :disabled="disabled">{{ $t("generic.next") }}</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "FluxFondosStep1",
  props: {
    fund: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      name: '',
      amount: '',
    }
  },
  computed: {
    disabled() {
      return !(this.name !== '' && this.amount !== '')
    }
  },
  methods: {
    next() {
      this.$emit('next',{
        name: this.name,
        amount: parseFloat(this.amount),
      })
    }
  }
};
</script>
