<template>
  <div>
    <template v-if="tip == 'acumular-pic'">
      <div class="shopbb-tips shopbb-tips--blue" @click.prevent="goToFaqRecompensas()">
        <div class="shopbb-tips__content">
          <div class="shopbb-tips__text-cover">
            <div class="shopbb-tips__cover">
              <img src="../../../assets/img/covers/ico-cover-pig.svg" alt="" />
            </div>
            <h3>¡Cómpralo en HelloBB y {{ list.ownerName }} acumulará un 5% de todo lo que gastes!</h3>
          </div>
        </div>
      </div>
    </template>
    <template v-if="tip == 'cambios-pic'">
      <div class="shopbb-tips shopbb-tips--pink" @click.prevent="goToFaqDevoluciones()">
        <div class="shopbb-tips__content">
          <div class="shopbb-tips__text-cover">
            <div class="shopbb-tips__cover">
              <img src="../../../assets/img/covers/ico-cover-refund.svg" alt="" />
            </div>
            <h3>¡Cómpralo en HelloBB y facilítale los cambios y devoluciones a {{ list.ownerName }}!</h3>
          </div>
        </div>
      </div>
    </template>
    <template v-if="tip == 'cambios-text'">
      <div class="shopbb-tips shopbb-tips--rectangles" @click.prevent="goToFaqDevoluciones()">
        <div class="shopbb-tips__content">
          <div class="shopbb-tips__text">
            <h3>¡Ayuda a {{ list.ownerName }} con los cambios y devoluciones!</h3>
            <h4>
              Los objetos que compres en HelloBB podrán ser devueltos sin complicaciones
            </h4>
          </div>
        </div>
      </div>
    </template>
    <template v-if="tip == 'acumular-text'">
      <div class="shopbb-tips shopbb-tips--percent" @click.prevent="goToFaqRecompensas()">
        <div class="shopbb-tips__content">
          <div class="shopbb-tips__text">
            <h3>¿Quieres ayudar a {{ list.ownerName }}?</h3>
            <h4>¡Compra en HelloBB y acumulará un 5% de todo lo que compres!</h4>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ListTips",
  props: {
    tip: {
      type: String,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToFaqDevoluciones() {
      //new tab
      window.open("/devoluciones", "_blank");
    },
    goToFaqRecompensas() {
      //new tab
      window.open("/programa-de-recompensas", "_blank");}
  },
};
</script>

<style lang="scss" scoped></style>
