<template>
  <portal to="modals" v-if="show">
    <transition name="bbFade" @after-enter="showContent = true" appear>
      <div class="modal" v-show="show" @click="dismiss()">
        <transition name="bbUp" @after-leave="dismiss()" appear>
          <div
            class="modal__card"
            :class="targetClass"
            v-show="showContent"
            @click.stop=""
          >
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back">
                  <div v-show="back" class="modal-back">
                    <a @click.prevent="$emit('back')">
                      <div class="modal-back__name">
                        <img
                          src="../../assets/img/ico/ico-arrow-back.svg"
                          loading="lazy"
                          alt="Volver"
                          class="back__arrow"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="modal__close">
                  <img
                    src="../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="close()"
                  />
                </div>
              </div>
              <div class="modal__content">
                <slot></slot>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>

<script>
function is_server() {
  return !(typeof window != "undefined" && window.document);
}

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    back: {
      type: Boolean,
      default: false,
    },
    targetClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    dismiss() {
      this.$emit("close");
    },
    close() {
      //if route name is 'constructor-product' then go back to the previous page
      if (this.$route.name === "constructor-product") {
        this.$router.go(-1);
      } else {
        this.showContent = false
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler: (show) => {
        if (show) {
          if (!is_server()) {
            document.body.classList.add("modal-open");
          }
        } else {
          if (!is_server()) {
            document.body.classList.remove("modal-open");
          }
        }
      },
    },
  },
  created() {
    const escapeHandler = (e) => {
      if (e.key === "Escape" && this.show) {
        this.dismiss();
      }
    };
    if (!is_server()) {
      document.addEventListener("keydown", escapeHandler);
    }

    this.$once("hook:destroyed", () => {
      if (!is_server()) {
        document.removeEventListener("keydown", escapeHandler);
      }
    });
  },
};
</script>
